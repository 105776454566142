* {
  box-sizing: border-box;
  margin: 0;
  /* border: 1px solid red; */
}

*:focus {
  outline: none;
}

::selection {
  background-color: var(--blueprint-medium);
}

.no-padding-bottom {
  padding-bottom: 0px !important;
}

.no-margin-bottom {
  margin-bottom: 0px !important;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.white-text {
  color: white;
}

:root {
  --blueprint-dark: #081820;
  --blueprint-medium: #1F3642;
  --blueprint-regular: #607C8B;
  --blueprint-light: #CCD7DD;
  --purple-regular: #A471F8;
  --purple-medium: #6C3CB9;
  --purple-light: #FFDFDF;
  --white: #FFFFFF;
  --black: #000000;
  --ui-red: #FA715F;
  --ui-orange-regular: #FAC55F;
  --ui-orange-light: #fce3b2;
  --ui-orange-medium: #ff8d14;
  --ui-orange-dark: #d35a04;
  --ui-green: #5DEB74;
}

body {
  height: 100dvh;
  width: 100dvw;
  overflow: hidden;

  font-family: 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #081820;
  color: var(--white);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.canvas {
  padding: 0px !important;
  gap: 0px !important;
}

.screen {
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
}

.dash-container {
  display: flex;
  flex-direction: column;
  gap: 64px;
  flex: 1;
  padding: 64px;
}

.admin-dash-container {
  flex: 1;
  overflow: hidden;
  display: flex;
}

.admin-dash-left {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 64px;
  border-right: 1px solid var(--blueprint-medium);
}

.admin-dash-right {
  overflow: scroll;
  width: 30%;
  padding: 64px;
}

.dash-50 {
  display: flex;
  flex-direction: column;
  width: 50%;
  padding: 64px 64px 0px 64px;
}

.dash-50-left {
  width: 50%;
  padding: 64px 160px 64px 64px;
  border-right: 1px solid var(--blueprint-medium);
}

.page-header-container {
  margin-bottom: 64px;
  display: flex;
  align-items: center;
  gap: 24px;
}

ul {
  padding: 0px;
}

ul li {
  list-style: none;
  margin-right: 32px;
  margin-bottom: 32px;
}

ol {
  padding-left: 18px;
}

ol li {
  padding-left: 4px;
  padding-bottom: 16px;
}

label {
  color: var(--white);
  font-size: 16px;
  font-weight: 700;
}

form textarea {
  all: revert;
  height: 80px;
  max-height: 150px;
  min-width: 320px;
  display: flex;
  padding: 8px;
  background-color: transparent;
  color: var(--white);
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid var(--blueprint-regular);
}

form textarea::placeholder {
  color: var(--blueprint-regular);
}

input {
  display: flex;
  height: 40px;
  padding: 0px 8px;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  background-color: transparent;
  color: var(--white);
  font-size: 16px;
  font-weight: 400;
  border-radius: 4px;
  border: 1px solid var(--blueprint-regular);
}

input::placeholder {
  color: var(--blueprint-regular);
  font-size: 16px;
  font-weight: 400;
}

.too-long {
  color: var(--ui-red);
}

button {
  display: flex;
  padding: 8px 24px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-size: 14px;
  font-weight: 700;
}

button:hover {
  cursor: pointer;
}

textarea {
  padding: 0;
  width: 100%;
  border: none;
  background-color: transparent;
  color: var(--blueprint-regular);
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 700;
  resize: none;
}

.textarea-mirror {
  width: 100%;
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-weight: 700;
  resize: none;
  position: absolute;
  visibility: hidden;
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.textarea-container {
  width: 100%;
  position: relative;
  display: flex;
}

.btn-icon {
  padding-left: 16px;
}

.btn-regular {
  border: 1px solid var(--blueprint-medium);
  border-radius: 4px;
  background: var(--blueprint-medium);
  color: var(--blueprint-light);
}

.btn-regular:hover {
  background-color: var(--blueprint-dark);
}

.btn-cta {
  border: 1px solid var(--purple-regular);
  border-radius: 4px;
  background: var(--purple-regular);
  color: var(--white);
}

.btn-cta:hover {
  background: var(--purple-medium);
}

.btn-favorite {
  border: 1px solid var(--purple-light);
  border-radius: 4px;
  background: var(--purple-light);
  color: var(--blueprint-dark);
}

.btn-favorite:hover {
  background: #FFBBBB;
}

.btn-header {
  background-color: transparent;
  padding: 8px;
  color: var(--white);
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
}

.btn-chevron {
  padding-left: 7px;
  padding-right: 9px;
}

.btn-hamburger {
  flex: 1;
  border-radius: 0px;
  border: none;
  display: flex;
  justify-content: flex-start;
  gap: 16px;
  padding-block: 16px;
  background-color: var(--blueprint-dark);
}

.btn-hamburger:hover {
  background-color: var(--blueprint-medium);
}

.btn-set-seed {
  border: 1px solid var(--ui-orange-light) !important;
  border-radius: 0px 8px 8px 0px;
  border: none;
  background-color: var(--ui-orange-light);
  color: var(--blueprint-dark);
}

.btn-set-seed:hover {
  background: var(--ui-orange-regular);
}

.btn-timer {
  width: 120px;
  border-radius: 0px 8px 8px 0px;
}

.btn-pause {
  border-left: 1px solid var(--blueprint-medium);
  border-top: 0px;
  border-right: 0px;
  border-bottom: 0px;
}

.btn-node {
  width: 24px;
  height: 24px;
  padding: 0px;
}

.btn-loading {
  background-color: var(--ui-orange-light);
  border: 1px solid var(--ui-orange-light);
  border-radius: 4px;
}

.sign-in-card-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.sign-in-card {
  display: flex;
  width: 400px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  border-radius: 8px;
  border: 1px solid var(--blueprint-medium);
}

.sign-in-card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
}

form {
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-self: stretch;
}

.input-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-self: stretch;
}

.header {
  width: 100dvw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 24px;
  border-bottom: 1px solid var(--blueprint-medium);
}

.header-left {
  flex: 1;
}

.header-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

.header-right {
  flex: 1;
  display: flex;
  justify-content: right;
}

.user-profile {
  display: flex;
  align-items: center;
  gap: 24px;
  padding: 8px 8px 8px 16px;
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
}

.hamburger-menu {
  display: flex;
  flex-direction: column;
  gap: 1px;
  position: absolute;
  top: 72px;
  min-width: 160px;
  background-color: var(--blueprint-medium);
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.60);
}

.hamburger-link-wrapper {
  text-decoration: none;
  display: flex;
}

.sessions-container {
  display: flex;
  flex-wrap: wrap;
}

.session-card {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 300px;
  padding: 16px;
  margin-bottom: 8px;
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
}

.session-card-title {
  color: var(--white);
  font-size: 24px;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.session-card-project {
  color: var(--blueprint-regular);
  font-size: 16px;
  font-weight: 400;
}

.controls-bar {
  min-height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  border-bottom: 1px solid var(--blueprint-medium);
}

.session-name-container {
  display: flex;
  align-items: bottom;
  gap: 8px;
}

.info-icon {
  position: relative;
  display: flex;
  align-items: flex-end;
  color: var(--blueprint-regular);
}

.session-details-card {
  position: absolute;
  width: 400px;
  top: 0px;
  left: 32px;
  padding: 24px;
  background-color: var(--blueprint-dark);
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
  box-shadow: 10px 10px 20px 0px rgba(0, 0, 0, 0.60);
}

.session-details-text {
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 24px;
  color: var(--blueprint-regular);
}

.session-details-assignees {
  margin-top: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.assignee-badge {
  margin: 0px;
  padding: 4px 14px;
  background-color: var(--purple-regular);
  color: var(--white);
  border-radius: 1000px;
  font-size: 14px;
  font-weight: 500;
}

.controls {
  display: flex;
  gap: 16px;
}

.control-container {
  height: 48px;
  overflow: hidden;
  display: flex;
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
}

.seed-input {
  height: auto;
  width: 300px;
  padding: 0px 16px;
  border: none;
}

.time {
  display: flex;
  align-items: center;
  gap: 2px;
  padding: 0px 16px;
  color: var(--white);
  font-size: 32px;
  font-weight: 700;
}

.tree-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.node-container {
  cursor: grab;
  overflow: visible;
}

.card {
  margin-top: 8px;
  margin-left: -2px;
  padding: 24px;
  background-color: var(--blueprint-dark);
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
  cursor: default;
}

.node-controls {
  display: flex;
  gap: 4px;
}

.idea-text {
  font-size: 16px;
  font-weight: 700;
}

.rd3t-link {
  stroke: var(--blueprint-regular) !important;
}

.instructions-card {
  display: flex;
  margin-top: 8px;
  padding: 16px;
  flex-direction: column;
  gap: 24px;
  border-radius: 8px;
  border: 1px solid var(--blueprint-regular);
  background: var(--blueprint-medium);
  color: var(--blueprint-light);
  font-size: 12px;
  cursor: default;
}

.instruction-header {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 700;
}

.add-session-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 64px;
}

.add-session-form {
  min-width: 320px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.add-session-assignees {
  flex: 1;
}

.assignees-container {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
}

.assignee-container {
  min-width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--blueprint-regular)
}

.assignee-container:hover {
  cursor: pointer;
}

.assigned {
  color: var(--white);
}

.member-container {
  display: flex;
  align-items: center;
}

.member-name {
  font-weight: 700;
}

.member-email {
  color: var(--blueprint-regular);
  font-size: 14px;
}

.member-details-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.avatar {
  margin-bottom: 16px;
  height: 64px;
  width: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  background-color: var(--blueprint-medium);
}

.avt-small {
  height: 48px;
  width: 48px;
  margin-bottom: 0px;
  margin-right: 16px;
}

.avt-member {
  color: var(--blueprint-regular);
  background-color: var(--blueprint-light);
}

.avt-assigned {
  background-color: var(--purple-regular);
  border: 1px solid var(--purple-regular);
}

.avt-assigned:hover {
  background-color: var(--purple-medium);
}

.session-info {
  margin-top: 64px;
}

.favorites-list {
  padding: 16px 16px 16px 0px;
  border-top: 1px solid var(--blueprint-medium);
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 24px;
  overflow: scroll;
}

.favorite {
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 24px;
  border: 1px solid var(--blueprint-medium);
  border-radius: 8px;
}

.expired-label {
  font-size: 16px;
  font-weight: 700;
  padding: 8px 24px;
  border-radius: 1000px;
  background-color: var(--ui-red);
}

.payment-container {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.plan-card {
  width: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  padding: 24px;
  border: 1px solid var(--blueprint-medium);
  border-radius: 4px;
}

.price {
  font-size: 40px;
  font-weight: 700;
  color: var(--purple-regular);
}

.trial-badge {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 24px 8px 18px;
  background-color: var(--ui-orange-light);
  color: var(--ui-orange-dark);
  font-weight: 500;
  border-radius: 1000px;
}

.feature {
  color: white;
  display: flex;
  align-items: center;
  gap: 8px;
}

iframe {
  border: none;
  width: auto;
  height: auto;
  margin: -8px;
  margin-bottom: -40px;
}

.loading-indicator {
  display: flex;
  align-items: center;
  gap: 0px;
  color: var(--blueprint-regular);
  border: 1px solid var(--blueprint-medium);
  border-radius: 1000px;
  padding: 0px 16px 0px 24px;
}